#main {
  p {
    margin-bottom: 2px;
  }
}

.spacer {
  margin-top: 52px;
}

h2 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 32px;
}

#companies {
  .company-item {
    margin-top: 12px;
    margin-bottom: 42px;

    .company-logo {
      text-align: center;
      img {
        max-width: 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .company-name {
      margin-top: 12px;
      text-align: center;
      font-weight: bold;
    }

    .company-url {
      text-align: center;

      a {
        text-decoration: underline;
      }
    }
  }
}

#start-page-logo {
  margin-top: 100px;
  margin-bottom: 32px;
  max-width: 244px;
}
